import React, { useEffect, useState } from 'react'
import './styles.scss'
import { key, url } from '../../Server/config';
import * as Icon from 'react-feather';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.png'
import loading from "../../assets/loaderGif.json";

const OnBoardingScreen = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const spinnerlgt = [
    1,
    2,
    3,
    4
  ]

  const chosenCategory = (id) => {
    localStorage.setItem('foryou', id)

    window.location.reload();
  }

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", key);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${url}/api/videos/categories`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setCategories(result.data)
      })
      .catch(error => console.log('error', error));
  }, []);

  return (
    <div className='onboardingscreen'>
      <div className='relat'>
        <h1>Choose your category</h1>
      </div>
      <div className='cartegoryflex'>
        {categories.length > 0 ? (
          <>
            {categories.map((item, index) => (
              <div className='each-cartegoryflex' key={item.id} onClick={() => chosenCategory(item.id)}>
                {/* <Link to={`/category/videos/${item.id}/${item.title}`} onClick={() => checkpath2(item.id)}>{item.title}</Link> */}
                <div className='img'>
                  <img src={logo} alt='' />
                </div>
                <p>{item.title}</p>
              </div>
            ))}</>
        ) : (
          <>
            {spinnerlgt.map((item, index) => (
              <div className='each-cartegoryflex' key={index}>
                <div className='img'>
                <Player
                autoplay
                loop
                src={loading}
                style={{ height: '100%', width: '100%' }}
              >
                <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
              </Player>
                </div>
              <p>Loading...</p>

              </div>
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export default OnBoardingScreen