import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { key, url } from '../../Server/config'
import './styles.scss'

const Subscription = () => {
  const [msisdn, setMsisdn] = useState(localStorage.getItem('MSISDN'))
  const [status, setStatus] = useState('Inactive')
  const [billDate, setBillDate] = useState('null')

  const [items, setItems] = useState([])


  useEffect(() => {
    const number = localStorage.getItem('MSISDN')
    // const number = '2347037076382'
    setMsisdn(number);

    var myHeaders = new Headers();
    myHeaders.append("x-api-key", key);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${url}/api/user/${number}/account`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // setPlay(result.data)
        if (result.data?.status === "Active") {
          // console.log(result.data.status)
          // const favouriteIds = result.data.favourites.map(Number);
          // setLikedVideos(favouriteIds);
          setStatus(result.data?.status)
          setMsisdn(number)
          setBillDate(result.data.expires)
        } else {
          // console.log(result)
          // setSubState(true)
          setStatus('Inactive')

        }
        // console.log(result.data)
      })
      .then(() => {
        fetch(`${url}/api/videos?order=published&dir=desc&pg=${1}&limit=20`, requestOptions)
          .then(response => response.json())
          .then(result => {
            setItems(result.data)
            // console.log('result', result)
          })
          .catch(error => console.log('error', error));
      })
      .catch(error => console.log('error', error));

  }, [])

  return (
    <div className='sub_container'>
      <div className='head_text'>
        <h1>SUBSCRIPTION</h1>
      </div>

      <div className='sect_1'>
        <div className='bill'>
          <p>Membership and Billing</p>
          <a href="http://ng-app.com/Nina_Jojer/Tapestry-168-No-23401220000028631-web?trxId={{RANDOM-UNIQUE-ID}}" target="_blank" rel="noreferrer">
          <button>SUBSCRIBE</button>
          </a>
        </div>
        <div className='sub_stat'>
          {status === 'Active'? (
            <p>Status: <span className='active'>{status}</span></p>
          ) : (
            <p>Status: <span className='inactive'>{status}</span></p>

          )}
          <p>Phone Number: <span className='bold'>{msisdn}</span></p>
          <p>Next Billing Date: <span className='bold'>{billDate}</span></p>
        </div>

        <div className='sub_links'>
          <div className='each_link'>
            <Link to>Change Plan</Link>
          </div>
          <div className='each_link'>
            <Link>Opt Out</Link>

          </div>
        </div>
      </div>

      {/* <div className='sect_2'>
        <div className='fade_bg_top'></div>
        <div className='content'>
          {items.slice(0, 10).map((item, index) => (
            <>
              <div key={index} className='each_banner'>
                <img src={item.banner} alt='' />
              </div>
              <div key={index} className='each_banner'>
                <img src={item.banner} alt='' />
              </div>
            </>
          ))}
        </div>
        <div className='fade_bg_btm'></div>
      </div> */}
    </div>
  )
}

export default Subscription