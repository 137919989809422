import React from 'react';
import Header from '../components/Header2';
import Footer from '../components/Footer';
import './Styles.scss';

const HomeLayout = props => {
  return (
    <div className="fullHeight">
      <Header {...props} />
      <div className="main">
        {props.children}
      </div>
      <Footer {...props} />
      </div>

  );
};

export default HomeLayout;
