import React, { useEffect, useRef, useState } from 'react'
import './styles.scss'
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import loaderGif from "../../assets/loaderGif.json";
import Img from '../../assets/main_bg.png'
import * as Icon from 'react-feather';
import { useParams } from 'react-router-dom';
import MoreLikeThis from '../MoreLikeThis/MoreLikeThis';
import { FaHeart, FaInfoCircle } from 'react-icons/fa';
import { key, url } from '../../Server/config';
import ShareButton from '../ShareButton';

const PlayerPage = () => {
  const { id } = useParams();
  const videoRef = useRef(null);
  const intId = parseInt(id);

  const [play, setPlay] = useState({})
  const [totalUsers, setTotalUsers] = useState(0)

  const [likedVideos, setLikedVideos] = useState([]);


  const [openInfo, setOpenInfo] = useState(false)
  const [isShare, setIsShare] = useState(false)


  const loadFavsfromApi = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", key);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${url}/api/user/2347037076382/account`, requestOptions)
      .then(response => response.json())
      .then(result => {
        const favouriteIds = result.data.favourites.map(Number);
        setLikedVideos(favouriteIds)
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    loadFavsfromApi()

    var myHeaders = new Headers();
    myHeaders.append("x-api-key", key);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${url}/api/videos/${id}/play`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setPlay(result.data)
        // console.log(result.data)

        const storedString = localStorage.getItem('totalUsers');
        console.log("storedString", storedString)

        const percentage = (result.data.likes / storedString) * 100
        console.log("likes", result.data.likes)

        setTotalUsers(percentage)

        console.log("%%", percentage)
      })
      .catch(error => console.log('error', error));
  }, [id]);

  const mediaUrl = play.media?.media_url; // Use optional chaining in case data isn't available yet

  const likeVid = (videoId) => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", key);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    //User MSISDN
    const number = '2347037076382'

    const isAlreadyLiked = likedVideos.includes(videoId);

    // Toggle liked status
    if (isAlreadyLiked) {
      fetch(`${url}/api/user/${number}/${videoId}/unlike`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log('remove', result.status)
          setLikedVideos(likedVideos.filter((id) => intId !== videoId));
        }).then(() => {
          loadFavsfromApi()

        })
        .catch(error => console.log('error', error));
    } else {
      fetch(`${url}/api/user/${number}/${videoId}/like`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log('add', result.status)
          setLikedVideos([...likedVideos, videoId]);
        }).then(() => {
          loadFavsfromApi()
        })
        .catch(error => console.log('error', error));
    }

  }

  const checkInfo = () => {
    if (openInfo) {
      setOpenInfo(false)
    } else {
      setOpenInfo(true)
    }
  }

  return (
    <div className='player_container'>
      {isShare && (
        <ShareButton onClose={()=> setIsShare(false)}/>
      )}

      <div className='player_inner_container'>
        <div className='col1'>
          <div className='player_container'>
            {mediaUrl && (
              <video controls autoPlay id="myVideo" ref={videoRef}>
                <source src={mediaUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>


          <div className='item_content'>
            <div className='title'>
              <p>{play.title}</p>
              {totalUsers > 0 && (
                <p className='fav_percent'>
                  <span className='bold'>{totalUsers}%</span> of viewers have added this as favourite
                </p>
              )}
            </div>
            {/* {totalUsers > 0 && (
              <p className='fav_percent'>
                <span className='bold'>{totalUsers}%</span> of viewers have added this as favourite
              </p>
            )} */}
            <div className='buttons'>
              {/* <p>1.2M Views</p> */}

              {likedVideos.includes(intId) ?
                (<FaHeart size={27} color="red" className='like' onClick={() => likeVid(intId)} />) :
                (<FaHeart size={27} color="white" className='like' onClick={() => likeVid(intId)} />)
              }

              <div className='each_btn right'>
                <div className='share' onClick={()=> setIsShare(true)}>
                  <Icon.Send color='#fff' size={25} />
                  <p>Share</p>
                </div>
                <div className='info' onClick={() => checkInfo()} >
                  <FaInfoCircle size={27} color="white" className='like' />
                </div>
              </div>

            </div>
          </div>
        </div>

        {openInfo && (
          <div className='info_content'>
            <div className='desc'>
              <p className='desc_title'>Description</p>
              <p className='desc_content'>{play.abstract}</p>
            </div>
            <div className='faces_cont'>
              <p className='faces_title'>Notable Faces</p>
              <div className='faces'>

                {play.artists.map((item) => (
                  <div className='each_faces' key={item.id}>
                    <img src={item.banner} alt='' />
                    <p>{item.name}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className='genre-flex'>
              {play.category.map((item) => (
                <p key={item.id}>{item.title}</p>
              ))}
            </div>
          </div>
        )}
        <MoreLikeThis id={intId} likes={likedVideos} categories={play.category} />
      </div>
    </div>
  )
}

export default PlayerPage