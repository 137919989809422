import React from 'react'
import './styles.scss'


const PopUp = ({onClose}) => {
    return (
        <div className='popup-container'>
            <div className='blk-overlay' onClick={()=> onClose()}></div>

            <div className='content'>
                <h2 className='title'>Your account is inactive. Try subscription?</h2>

                <p className='body'>
                    Subscribe to a daily, weekly or monthly plan to keep enjoying.
                </p>

                <a href="http://ng-app.com/Nina_Jojer/Tapestry-168-No-23401220000028631-web?trxId={{RANDOM-UNIQUE-ID}}" target="_blank" rel="noreferrer">
                <button>SUBSCRIBE</button>
                </a>
            </div>

        </div>
    )
}

export default PopUp