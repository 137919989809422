import React, { useState } from 'react';
import * as Icon from 'react-feather';
import './styles.scss';

const ScrollButton = () => {

    const [visible, setVisible] = useState(false);

    const toggleVisible = () =>{
        const scrolled = document.documentElement.scrollTop;

        if(scrolled > 300){
            setVisible(true)
        }else if (scrolled <= 300){
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    window.addEventListener('scroll', toggleVisible);

  return (
    <div className={visible? 'backtotop active' : 'backtotop'} onClick={scrollToTop}>
          <Icon.ArrowUp color='#05151E'/>
    </div>
  );
}

export default ScrollButton