import React from 'react'
import './styles.scss'
import * as Icon from 'react-feather';

const Footer = () => {
  return (
      <div className='footer-container'>
      <div className='social-links'>
        <a href='/'>
          <Icon.Instagram color='white' size={20}/>
        </a>

        <a href='/'>
          <Icon.Linkedin color='white' size={20} />
        </a>

        <a href='/'>
          <Icon.Twitter color='white' size={20} />

        </a>
      </div>

      <p className='txt'>Copyright © 2023 UWN.</p>

      <div className='right-links'>
        <ul>
          <li>Help Center</li>
          <li>About Us</li>
        </ul>
      </div>
      
      </div>
  )
}

export default Footer