import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import bubble from "../assets/bubble.json";
import './Styles.scss';



const HomeLayout = props => {
  
  return (
    <div className="fullHeight">
      <Header {...props} />
      <div className="main">
        {props.children}
      </div>
      <Footer {...props} />
    </div>

  );
};

export default HomeLayout;
