import React, { useEffect, useState } from 'react'
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import notFound from "../../assets/notfound.json";
import { FaHeart } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import './styles.scss'
import { key, url } from '../../Server/config';


const Favourite = () => {
  const navigate = useNavigate();
  const [userLikes, setUserLikes] = useState([]);
  const [likedVideos, setLikedVideos] = useState([]);

  const openPlayer = (index) => {
    navigate(`/videos/${index}/play`);
  }

  const loadFavsfromApi = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", key);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${url}/api/user/2347037076382/account`, requestOptions)
      .then(response => response.json())
      .then(result => {
        const favouriteIds = result.data.favourites.map(Number);
        setLikedVideos(favouriteIds)

        var myHeaders = new Headers();
        myHeaders.append("x-api-key", key);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        fetch(`${url}/api/videos?order=published&dir=desc&pg=1&limit=20`, requestOptions)
          .then(response => response.json())
          .then(result => {
            const videos = result.data
            const favoriteVideos = videos.filter(video => favouriteIds.includes(video.id));
            setUserLikes(favoriteVideos);
          })
          .catch(error => console.log('error', error));
      }).then(() => {

      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    loadFavsfromApi()
  }, []);

  const likeVid = (videoId) => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", key);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    //User MSISDN
    const number = '2347037076382'

    const isAlreadyLiked = likedVideos.includes(videoId);
    // console.log(likedVideos)

    // Toggle liked status
    if (isAlreadyLiked) {
      fetch(`${url}/api/user/${number}/${videoId}/unlike`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result.status)
          setLikedVideos(likedVideos.filter((id) => id !== videoId));
        }).then(() => {
          loadFavsfromApi()

        })
        .catch(error => console.log('error', error));
    } else {
      fetch(`${url}/api/user/${number}/${videoId}/like`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result.status)
          setLikedVideos([...likedVideos, videoId]);
        }).then(() => {
          loadFavsfromApi()
        })
        .catch(error => console.log('error', error));
    }



  }

  return (
    <div className='category_container'>
      <div className='category_inner_container'>
        <div>
          {userLikes.length === 0 ? (
            <div>
              <Player
                autoplay
                loop
                src={notFound}
                style={{ height: '100%', width: '100%' }}
              >
                <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
              </Player>
            </div>) : (
            <div className='item_row'>
              {userLikes.map((item, index) => (
                <div className='each_ups' key={item.id}>
                  <img src={item.banner} alt='' onClick={() => openPlayer(item.id)} />
                  <div className='dtl_flex'>
                    <p onClick={() => openPlayer(item.id)}>{item.title}</p>
                    {likedVideos.includes(item.id) ?
                      (<FaHeart size={20} color="red" className='like' onClick={() => likeVid(item.id)} />) :
                      (<FaHeart size={20} color="white" className='like' onClick={() => likeVid(item.id)} />)
                    }
                  </div>
                </div>
              ))
              }
            </div>)}


        </div>
      </div>
    </div>
  )
}

export default Favourite