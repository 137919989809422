import React, { useEffect, useState } from 'react'
import './styles.scss'
import logo from '../../assets/logo.png'
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { key, url } from '../../Server/config';


const Header = () => {
  const navigate = useNavigate();
  const [navbar, setNavbar] = useState(false);
  const [searchVal, setSearchVal] = useState('');

  const navs = [
    { id: 1, text: 'Home', url: '/' },
    { id: 2, text: 'Category', url: '' },
    { id: 3, text: 'Favourite', url: '/favourite' },
    { id: 4, text: 'Subscription', url: '/subscription' },
  ];

  const [active, setActive] = useState(parseInt(localStorage.getItem('Navkey')) || 1);
  const [subNav, setSubNav] = useState(null);
  const [navState, setNavState] = useState(false);

  const [categories, setCategories] = useState([]);


  const [mobileNav, setMobileNav] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 18) {

      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener('scroll', changeBackground);

  const checkpath = (index) => {
    if (navState) {
      setNavState(false)
    } else {
      setNavState(true)
    }
    if (index !== 2) {
      localStorage.setItem('Navkey', index);
      setActive(index)
      setSubNav(index)
      openNav()
    } else {
      setSubNav(index)
      setActive(index)
    }

    // console.log('active', index)

  }

  const checkpath2 = (index) => {
    // localStorage.setItem('Navkey', index);
    // setActive(index)
    openNav()

    if (navState) {
      setNavState(false)
    } else {
      setNavState(true)
    }
    // setSubNav(index)

    // console.log('active', index)


  }

  const openNav = () => {
    if (mobileNav) {
      setMobileNav(false)
    } else {
      setMobileNav(true)
    }
  }

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", key);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${url}/api/videos/categories`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setCategories(result.data)
      })
      .catch(error => console.log('error', error));
  }, []);

  const startSearch = () => {
    navigate(`/category/videos/0/default/${searchVal}`);

  }

  return (
    <div className={navbar ? (active === 4 ? 'header-container4 active4' : 'header-container active') : (active === 4 ? 'header-container4' : 'header-container')}>
      <div className='links'>
        <Link to='/' className='logo'>
          <img src={logo} alt='' />
        </Link>

        <ul className='desk_ul'>
          {navs.map((item) => (
            <li key={item.id} className={active === item.id ? 'active' : 'act'}>
              <Link to={item.url} onClick={() => checkpath(item.id)} >{item.text}</Link>
              {navState && item.id === 2 && subNav === 2 && (
                <div className="dropdown-content">
                  {categories.map((item, index) => (
                    <div className='each-sub-link' key={item.id}>
                      <Link to={`/category/videos/${item.id}/${item.title}`} onClick={() => checkpath2(item.id)}>{item.title}</Link>
                    </div>
                  ))}
                </div>
              )}
            </li>
          ))}

        </ul>

        <ul className={mobileNav ? 'mob_ul active' : 'mob_ul'}>
          <div className='close_btn_container' >
            <Icon.X className='close_btn' onClick={openNav} />
          </div>
          {navs.map((item) => (
            <li key={item.id} onClick={() => checkpath(item.id)} className={active === item.id ? 'active' : 'act'}>
              <Link to={item.url}>{item.text}</Link>
              {navState && item.id === 2 && subNav === 2 && (
                <div className="dropdown-content">
                  <div className="dropdown-content">
                    {categories.map((item, index) => (
                      <div className='each-sub-link' key={item.id}>
                        <Link to={`/category/videos/${item.id}/${item.title}`} onClick={() => checkpath2(item.id)}>{item.title}</Link>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </li>
          ))}

          <div className='mob_search'>
            <Icon.Search color='#fff' size={18} />
          </div>

        </ul>

        {mobileNav && (
          <div className='blur_bg_overlay' onClick={openNav}></div>
        )}

        <div className='mob_nav'>
          <Icon.Menu color='#fff' onClick={openNav} />
        </div>
      </div>

      <form action={`/category/videos/0/default/${searchVal}`}>
        <input type="search" placeholder="Search" onChange={(e) => setSearchVal(e.target.value)} />
        <Icon.Search color='#fff' size={20} onClick={startSearch} />
      </form>

    </div>
  )
}

export default Header