import React, { useEffect, useRef, useState } from 'react'
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import notFound from "../../assets/notfound.json";
import { FaHeart } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import * as Icon from 'react-feather';
import loaderGif from "../../assets/loaderGif.json";
import './styles.scss'
import { key, url } from '../../Server/config';

const MoreLikeThis = ({ id, likes, categories }) => {
    const navigate = useNavigate();
    const carsl = useRef();
    const videoRef = useRef(null);


    const [displayedItemsCount, setDisplayedItemsCount] = useState(6);
    const [play, setPlay] = useState({})

    const [items, setItems] = useState([]);
    const [categoriesList, setCategoriesList] = useState([]);

    const [userLikes, setUserLikes] = useState([]);
    const likedVideos = likes;

    const openPlayer = (index) => {
        navigate(`/videos/${index}/play`);
        window.location.reload();
    }

    const loadFavsfromApi = () => {
        // console.log(categories)

        var myHeaders = new Headers();
        myHeaders.append("x-api-key", key);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${url}/api/videos?order=published&dir=desc&pg=1&limit=20`, requestOptions)
            .then(response => response.json())
            .then(result => {
                const videos = result.data
                const favoriteVideos = videos.filter(video => likes.includes(video.id));
                // console.log('likes', likes)

                setUserLikes(favoriteVideos);

                // console.log('catgories', categories)

                const filteredVideos = videos.filter(item => {
                    const categoryIds = item.category.map(cat => cat.id);
                    const matchingCategories = categoryIds.filter(id => categories.some(cat => cat.id === id));
                    return matchingCategories.length > 0;
                });

                setItems(filteredVideos)

            })
            .catch(error => console.log('error', error));


    }

    useEffect(() => {
        loadFavsfromApi()
        setCategoriesList(categories)

    }, [categories]);

    const likeVid = (videoId) => {
        var myHeaders = new Headers();
        myHeaders.append("x-api-key", key);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        //User MSISDN
        const number = '2347037076382'

        const isAlreadyLiked = likedVideos.includes(videoId);
        // console.log(likedVideos)

        // Toggle liked status
        if (isAlreadyLiked) {
            fetch(`${url}/api/user/${number}/${videoId}/unlike`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result.status)
                    setUserLikes(likedVideos.filter((id) => id !== videoId));
                }).then(() => {
                    loadFavsfromApi()

                })
                .catch(error => console.log('error', error));
        } else {
            fetch(`${url}/api/user/${number}/${videoId}/like`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result.status)
                    setUserLikes([...likedVideos, videoId]);
                }).then(() => {
                    loadFavsfromApi()
                })
                .catch(error => console.log('error', error));
        }

    }

    const [carlsloader, setCarlsloader] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const container = carsl.current;

            if (container) {
                const scrollThreshold = container.scrollWidth - container.offsetWidth;

                if (container.scrollLeft >= scrollThreshold) {
                    setCarlsloader(true)

                    setTimeout(() => {
                        setDisplayedItemsCount(prevCount => prevCount + 3);
                        setCarlsloader(false)
                    }, 2000);

                } else {
                    setCarlsloader(false)

                }
            }
        };

        carsl.current.addEventListener('scroll', handleScroll);

        return () => {
            // carsl.current.removeEventListener('scroll', handleScroll);
        };
    }, [carsl]); // Add carslWidth to the dependency array

    return (
        <div className='morelikethis_container'>
            <div className='col2'>
                <h2>More Like This</h2>

                <div className='col-inner-cont'>
                    <div className='uploads_container' ref={carsl} >
                        <div className='uploads_row'>

                            {items.slice(0, displayedItemsCount).map((item, index) => (
                                <div className='each_ups' key={index}>
                                    <img src={item.banner} alt='' onClick={() => openPlayer(item.id)} />
                                    <div className='dtl_flex'>
                                        <p onClick={() => openPlayer(item.id)}>{item.title}</p>
                                        {likedVideos.includes(item.id) ?
                                            (<FaHeart size={20} color="red" className='like' onClick={() => likeVid(item.id)} />) :
                                            (<FaHeart size={20} color="white" className='like' onClick={() => likeVid(item.id)} />)
                                        }
                                    </div>
                                </div>
                            ))}


                        </div>

                    </div>
                    {carlsloader && (
                        <div className='loader'>
                            <Player
                                autoplay
                                loop
                                src={loaderGif}
                                style={{ height: '150px', width: '150px' }}
                            >
                                <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                            </Player>
                        </div>)}
                </div>
            </div>
        </div>
    )
}

export default MoreLikeThis