import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import MainLayout from './layouts/MainLayout';
import './App.css'
import HomeLayout from './layouts/HomeLayout';

import HomePage from './components/HomePage';
import ScrollButton from "./components/ScrollButton";
import Category from "./components/Category";
import Favourite from "./components/Favourite";
import Subscription from "./components/Subscription";
import PlayerPage from "./components/PlayerPage";
import PlayerLayout from './layouts/PlayerLayout';
import { url } from "./Server/config";
import { useEffect, useState } from "react";
import Logo from './assets/logo.png';
import OnBoardingScreen from "./components/OnBoardingScreen";

function App() {
  const [status, setStatus] = useState(false)
  const [hasSeenOnboard, setHasSeenOnboard] = useState(false);

  useEffect(() => {
    const foryou = localStorage.getItem('foryou')
    if (foryou !== null) {
      setHasSeenOnboard(true)
    } else {
      setHasSeenOnboard(false)
    }
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", "kJraGrGD2ElrfLcgAEyRpDpvhaVsPlhK");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${url}/api/videos/landing`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === 'ok') {
          setTimeout(() => {
            setStatus(true);

          }, 2000);
        } else {
          setStatus(false);
          // alert(result)
        }

      }).then(() => {
        var myHeaders = new Headers();
        myHeaders.append("x-api-key", "kJraGrGD2ElrfLcgAEyRpDpvhaVsPlhK");

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        fetch(`${url}/api/users`, requestOptions)
          .then(response => response.json())
          .then(result => {
            localStorage.setItem('totalUsers', result.total);
          })
          .catch(error => console.log('error', error));
      })
      .catch(error => console.log('error', error));

  }, [])

  return (
    <div className="App">
      <div className={status ? 'loading active' : 'loading'}>
        <div className='blk-item'>
          <img src={Logo} alt='' />

          <p>Welcome to Universal Worship Network</p>

          {/* <div className='player'>
            <Player
              autoplay
              loop
              src={bubble}
              style={{ height: '100%', width: '100%' }}
            >
              <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
            </Player>
          </div> */}
        </div>

      </div>
      <Router>

        <Routes>
          <Route path="/"
            element={hasSeenOnboard ?
              <HomeLayout>
                <HomePage />
              </HomeLayout> :
              <OnBoardingScreen />
            }
          />

          {/* <Route path="/" element={
            <HomeLayout>
              <HomePage />
            </HomeLayout>
          }
          /> */}

          <Route path="/onboarding" element={
            // <No>
            <OnBoardingScreen />
            // </No>
          }
          />

          <Route path="/category/videos/:id/:category/:search" element={
            <MainLayout>
              <Category />
            </MainLayout>
          }
          />

          <Route path="/category/videos/:id/:category" element={
            <MainLayout>
              <Category />
            </MainLayout>
          }
          />

          <Route path="/videos/:id/play" element={
            <PlayerLayout>
              <PlayerPage />
            </PlayerLayout>
          }
          />

          <Route path="/favourite" element={
            <MainLayout>
              <Favourite />
            </MainLayout>
          }
          />

          <Route path="/subscription" element={
            <MainLayout>
              <Subscription />
            </MainLayout>
          }
          />

        </Routes>
      </Router>

      <ScrollButton />
    </div>
  );
}

export default App;
