import React, { useRef, useState } from 'react';
import './styles.scss'
import * as Icon from 'react-feather';
import insta from '../../assets/instagram.png'
import fb from '../../assets/facebook.png'
import twitter from '../../assets/twitter.png'
import whatsapp from '../../assets/whatsapp.png'

const ShareButton = ({onClose}) => {
    const currentUrl = window.location.href;
    const [iscopied, setisCopied] = useState(false)

    const shareOnTwitter = () => {
        const tweetText = 'Check out this link!';
        const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}&url=${encodeURIComponent(currentUrl)}`;
        window.open(twitterShareUrl, '_blank');
    };

    const shareOnFacebook = () => {
        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`;
        window.open(facebookShareUrl, '_blank');
    };

    const shareOnLinkedIn = () => {
        const linkedInShareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(currentUrl)}`;
        window.open(linkedInShareUrl, '_blank');
    };

    const shareOnWhatsApp = () => {
        const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(`Check out this link: ${currentUrl}`)}`;
        window.open(whatsappShareUrl, '_blank');
    };

    const shareOnPinterest = () => {
        const pinterestShareUrl = `https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(currentUrl)}`;
        window.open(pinterestShareUrl, '_blank');
    };

    const shareOnInstagram = () => {
        const instagramAppUrl = `https://www.instagram.com/direct?text=${encodeURIComponent(currentUrl)}&url=${encodeURIComponent(currentUrl)}`;
        window.open(instagramAppUrl, '_blank');
      };

    const triggerOnClick = () => {
        if (typeof onClose === 'function') {
          onClose();
        } else {
          console.error('onClickFunction is not a function');
        }
      };

      const textAreaRef = useRef(null);

  const handleCopyClick = () => {
    if (textAreaRef.current) {
      // Create a range and select the text
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(textAreaRef.current);
      selection.removeAllRanges();
      selection.addRange(range);

      // Execute the copy command
      document.execCommand('copy');
        setisCopied(true)
      // Deselect the text
      selection.removeAllRanges();
    }
  };

    return (
        <div className='share-container'>
            <div className='share-inner-container'>
                <div className='flex space-between top-header'>
                    <p>Share</p>
                    <button onClick={triggerOnClick}>X</button>
                </div>
                <div className='flex center'>
                    <div className='each-social-share' onClick={shareOnWhatsApp}>
                        <img src={whatsapp} alt=''/>
                        <p>WhatApp</p>
                    </div>
                    <div className='each-social-share' onClick={shareOnTwitter}>
                    <img src={twitter} alt=''/>
                        <p>X</p>
                    </div>
                    <div className='each-social-share' onClick={shareOnFacebook}>
                    <img src={fb} alt=''/>
                        <p>Facebook</p>
                    </div>
                    {/* <div className='each-social-share' onClick={shareOnInstagram}>
                    <img src={insta} alt=''/>
                        <p>Instagram</p>
                    </div> */}
                </div>

                <div className='link-copy flex space-between'>
                    <p ref={textAreaRef} >{currentUrl}</p>
                    {iscopied? (
                    <button className='copied' onClick={handleCopyClick}>Copied</button>

                    ):(
                        <button className='copy' onClick={handleCopyClick}>Copy</button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ShareButton;