import React, { useEffect, useRef, useState } from 'react'
import './styles.scss'
import * as Icon from 'react-feather';
import Play from '../../assets/play.png'
import Img from '../../assets/main_bg.png'
import Logo from '../../assets/logo.png'
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import loaderGif from "../../assets/loaderGif.json";
import lazyLoader from "../../assets/lazyload.json";
import { useNavigate } from 'react-router-dom';
import { FaHeart } from 'react-icons/fa';
import { key, url } from '../../Server/config';
import PopUp from '../PopUp';

const HomePage = () => {
    const navigate = useNavigate();
    const videoRef = useRef(null);
    const videoRef2 = useRef(null);

    const carsl = useRef();
    const carsl2 = useRef();
    const carsl3 = useRef();
    const carsl4 = useRef();

    const [displayedItemsCount, setDisplayedItemsCount] = useState(6);
    const [displayedItemsCount2, setDisplayedItemsCount2] = useState(6);
    const [displayedItemsCount3, setDisplayedItemsCount3] = useState(6);
    const [displayedItemsCount4, setDisplayedItemsCount4] = useState(6);

    const [carlsloader, setCarlsloader] = useState(false);
    const [carls2loader, setCarls2loader] = useState(false);
    const [carls3loader, setCarls3loader] = useState(false);
    const [carls4loader, setCarls4loader] = useState(false);
    const [videoDuration, setVideoDuration] = useState('');


    const [featured, setFeatured] = useState({});
    const [latest, setLatest] = useState([]);
    const [mostWatched, setMostWatched] = useState([]);
    const [pickedforYou, setPickedForYou] = useState([]);
    const [prefCart, setPrefCart] = useState([]);
    const [favourite, setFavourite] = useState([]);

    const [ftDuration, setFtDuration] = useState('');
    const [ftDate, setFtDate] = useState('');
    const [ftartist, setFtartist] = useState([]);
    const [ftcat, setFtcat] = useState([]);
    const mediaUrl = featured.media?.media_url; // Use optional chaining in case data isn't available yet
    const [likedVideos, setLikedVideos] = useState([]);
    const [isShown, setIsShown] = useState(null);
    const [isCategory, setIsCategory] = useState(null);
    const [isHovered, setIsHovered] = useState(false);

    // const [showPrevVid, setSHowPrevVid] = useState(false);
    const [subState, setSubState] = useState(false);
    let timeoutId;


    useEffect(() => {
        if (mediaUrl) {
            const videoElement = videoRef.current;

            // Add event listener to wait for metadata to load
            videoElement.addEventListener('loadedmetadata', () => {
                // Get the duration in seconds
                const durationInSeconds = Math.floor(videoElement.duration);

                let durationDisplay;
                if (durationInSeconds >= 60) {
                    const minutes = Math.floor(durationInSeconds / 60);
                    const seconds = durationInSeconds % 60;
                    durationDisplay = `${minutes} min ${seconds} sec`;
                } else {
                    durationDisplay = `${durationInSeconds} sec`;
                }

                setFtDuration(durationDisplay)

            });

            return () => {
                // Remove event listener when component unmounts
                videoElement.removeEventListener('loadedmetadata', () => { });
            };
        }

        if (videoRef2.current) {

            const videoElement = videoRef2.current;

            // Add event listener to wait for metadata to load
            videoElement.addEventListener('loadedmetadata', () => {
                // Get the duration in seconds
                const durationInSeconds = Math.floor(videoElement.duration);

                let durationDisplay;
                if (durationInSeconds >= 60) {
                    const minutes = Math.floor(durationInSeconds / 60);
                    const seconds = durationInSeconds % 60;
                    durationDisplay = `${minutes} min ${seconds} sec`;
                } else {
                    durationDisplay = `${durationInSeconds} sec`;
                }

                // Update the videoDuration state with the durationDisplay value
                setVideoDuration(durationDisplay);
            });

            return () => {
                // Remove event listener when component unmounts
                videoElement.removeEventListener('loadedmetadata', () => { });
            };
        }
    }, [mediaUrl, videoRef2]);
    // const checkScroll = () => {
    //     const videoElement = document.getElementById('myVideo');
    //     if (window.scrollY >= 200) {
    //         if (videoElement) {
    //             videoElement.pause();
    //             }
    //     } else {
    //         if (videoElement) {
    //             // videoElement.play();

    //         }
    //     }
    // };
    // window.addEventListener('scroll', checkScroll);

    useEffect(() => {
        const handleScroll = () => {
            const container = carsl.current;

            if (container) {
                const scrollThreshold = container.scrollWidth - container.offsetWidth;

                if (container.scrollLeft >= scrollThreshold) {
                    setCarlsloader(true)

                    setTimeout(() => {
                        setDisplayedItemsCount(prevCount => prevCount + 3);
                        setCarlsloader(false)
                    }, 2000);

                } else {
                    setCarlsloader(false)

                }
            }
        };

        carsl.current.addEventListener('scroll', handleScroll);

        return () => {
            // carsl.current.removeEventListener('scroll', handleScroll);
        };
    }, [carsl]); // Add carslWidth to the dependency array

    useEffect(() => {
        const handleScroll = () => {
            const container = carsl2.current;

            if (container) {
                const scrollThreshold = container.scrollWidth - container.offsetWidth;

                if (container.scrollLeft >= scrollThreshold) {
                    setCarls2loader(true)

                    setTimeout(() => {
                        setDisplayedItemsCount2(prevCount => prevCount + 3);
                        setCarls2loader(false)
                    }, 2000);

                } else {
                    setCarls2loader(false)

                }
            }
        };

        carsl2.current.addEventListener('scroll', handleScroll);

        return () => {
            // carsl2.current.removeEventListener('scroll', handleScroll);
        };
    }, [carsl2]);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const container = carsl3.current;

    //         if (container) {
    //             const scrollThreshold = container.scrollWidth - container.offsetWidth;

    //             if (container.scrollLeft >= scrollThreshold) {
    //                 setCarls3loader(true)

    //                 setTimeout(() => {
    //                     setDisplayedItemsCount3(prevCount => prevCount + 3);
    //                     setCarls3loader(false)
    //                 }, 2000);

    //             } else {
    //                 setCarls3loader(false)

    //             }
    //         }
    //     };

    //     carsl3.current.addEventListener('scroll', handleScroll);

    //     return () => {
    //         // carsl3.current.removeEventListener('scroll', handleScroll);
    //     };
    // }, [carsl3]); // Add carslWidth to the dependency array

    useEffect(() => {
        const handleScroll = () => {
            const container = carsl4.current;

            if (container) {
                const scrollThreshold = container.scrollWidth - container.offsetWidth;

                if (container.scrollLeft >= scrollThreshold) {
                    setCarls4loader(true)

                    setTimeout(() => {
                        setDisplayedItemsCount4(prevCount => prevCount + 3);
                        setCarls4loader(false)
                    }, 2000);

                } else {
                    setCarls4loader(false)

                }
            }
        };

        carsl4.current.addEventListener('scroll', handleScroll);

        return () => {
            // carsl4.current.removeEventListener('scroll', handleScroll);
        };
    }, [carsl4]);

    const openPlayer = (index) => {
        navigate(`/videos/${index}/play`);
    }

    useEffect(() => {
        const msisdn = localStorage.getItem('MSISDN');
        // console.log(msisdn)

        var myHeaders = new Headers();
        myHeaders.append("x-api-key", key);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        const number = '2347037076382'
        fetch(`${url}/api/user/${msisdn}/account`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.data?.status === "Active") {
                    // console.log(result.data.status)
                    // const favouriteIds = result.data.favourites.map(Number);
                    // setLikedVideos(favouriteIds);
                    setSubState(false)

                } else {
                    // console.log(result)
                    // setSubState(true)

                }

            })
            .then(() => {
                const msisdn = localStorage.getItem('MSISDN');
                // console.log(msisdn)
                var myHeaders = new Headers();
                myHeaders.append("x-api-key", key);

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };

                fetch(`${url}/api/videos/landing?msisdn=${msisdn}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        // console.log(result.data.featured.media.media_url)
                        setFtartist(result.data.featured.artists)
                        setFeatured(result.data.featured)
                        setFtcat(result.data.featured.category)
                        setFtDate(result.data.featured.published.split(',')[0].trim())
                        setLatest(result.data.latest)
                        setMostWatched(result.data.most_watched)
                        setPickedForYou(result.data.picked_for_you)

                        const cartPrefId = localStorage.getItem('foryou');

                        fetch(`${url}/api/videos?order=published&dir=desc&pg=${1}&limit=20&category=${cartPrefId}&search=${''}`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                setPrefCart(result.data)
                            })
                            .catch(error => console.log('error', error));

                    }).then(() => {
                        var myHeaders = new Headers();
                        myHeaders.append("x-api-key", key);

                        var requestOptions = {
                            method: 'GET',
                            headers: myHeaders,
                            redirect: 'follow'
                        };

                        fetch(`${url}/api/users`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                localStorage.setItem('totalUsers', result.total);
                            })
                            .catch(error => console.log('error', error));
                    })
                    .catch(error => console.log('error', error));
            }).catch(error => console.log('error', error));

    }, []);




    const likeVid = (videoId) => {
        var myHeaders = new Headers();
        myHeaders.append("x-api-key", key);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        const msisdn = localStorage.getItem('MSISDN');


        //User MSISDN
        const number = '2347037076382'

        const isAlreadyLiked = likedVideos.includes(videoId);
        // console.log(likedVideos)

        // Toggle liked status
        if (isAlreadyLiked) {
            fetch(`${url}/api/user/${msisdn}/${videoId}/unlike`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result.status)
                    setLikedVideos(likedVideos.filter((id) => id !== videoId));
                })
                .catch(error => console.log('error', error));
        } else {
            fetch(`${url}/api/user/${msisdn}/${videoId}/like`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result.status)
                    setLikedVideos([...likedVideos, videoId]);
                })
                .catch(error => console.log('error', error));
        }



    }

    const handleMouseEnter = (index, category) => {
        // clearTimeout(timeoutId);
        // timeoutId = setTimeout(() => {
        setIsCategory(category)
        setIsShown(index)
        // }, 2000);
        setIsHovered(true);


    };

    const handleMouseLeave = () => {
        setIsShown(null)
        clearTimeout(timeoutId);
        setIsHovered(false);
        setIsCategory(null)
    };
    return (
        <>
            {subState && (
                <PopUp onClose={() => setSubState(false)} />
            )}

            <div className='homepage_container'>
                <div className='inner_container'>

                    <div className='col1'>
                        <div className='bg'>
                            {/* <img src={featured.banner} alt='' /> */}
                            {/* <VideoPlayer videoSource={ftmedia} /> */}
                            {/* <VideoJS options={videoJsOptions} onReady={handlePlayerReady} /> */}
                            {mediaUrl && (
                                <video autoPlay loop id="myVideo" ref={videoRef}>
                                    <source src={mediaUrl} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            )}

                        </div>
                        <div className='artist_flex'>
                            {ftartist.map((item) => (
                                <p key={item.id}>{item.name}</p>
                            ))}
                        </div>
                        <h1>{featured.title}</h1>
                        <div className='genre-flex'>
                            {ftcat.map((item) => (
                                <p key={item.id}>{item.title}</p>
                            ))}
                        </div>
                        <div className='desc'>
                            <p className='desc_title'>Description</p>
                            <p className='desc_content'>{featured.abstract}</p>
                        </div>
                        <div className='faces_cont'>
                            <p className='faces_title'>Notable Faces</p>
                            <div className='faces'>

                                {ftartist.map((item) => (
                                    <div className='each_faces' key={item.id}>
                                        <img src={item.banner} alt='' />
                                        <p>{item.name}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='btns_flex'>
                            <button onClick={() => openPlayer(featured.id)}>
                                {/* <Icon.Play color='#fff' size={32}/> */}
                                <img src={Play} alt='' />
                            </button>
                            <div className='transp_btn'>
                                <p className='flip'>RELEASED</p>
                                <p className='date_value'> {ftDate}</p>
                            </div>

                            <div className='transp_btn'>
                                <p className='flip2'>LENGTH</p>
                                <p className='lgt_value'>{ftDuration}</p>
                            </div>
                        </div>
                    </div>
                    <div className='fade_bg'></div>               

                    <div className='col2'>
                        {prefCart.length > 0 && (
                            <h2>Based on your prefered category</h2>
                        )}

                        <div className='col-inner-cont'>
                            <div className='uploads_container' ref={carsl} >
                                <div className='uploads_row'>

                                    {prefCart.slice(0, displayedItemsCount).map((item, index) => (
                                        <div className={isShown === item.id && isCategory === 'foryou' ? 'each_ups active' : 'each_ups'} key={index}
                                            onMouseEnter={() => handleMouseEnter(item.id, 'foryou')}
                                            onMouseLeave={() => handleMouseLeave()}>



                                            {item.media?.media_url && isShown === item.id && isCategory === 'foryou' && isHovered ? (
                                                <video autoPlay muted loop id="myVideo" ref={videoRef2} onClick={() => openPlayer(item.id)}>
                                                    <source src={item.media?.media_url} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            ) : (
                                                <img src={item.banner} alt='' onClick={() => openPlayer(item.id)} />
                                            )}
                                            <div className='dtl_flex'>
                                                <p onClick={() => openPlayer(item.id)}>{item.title}</p>
                                                <p className='duration'>{videoDuration}</p>
                                                {/* {likedVideos.includes(item.id) ?
                                                    (<FaHeart size={20} color="red" className='like' onClick={() => likeVid(item.id)} />) :
                                                    (<FaHeart size={20} color="white" className='like' onClick={() => likeVid(item.id)} />)
                                                } */}
                                            </div>
                                            <p className='title-mobile'>{item.title}</p>
                                            
                                        </div>
                                    )

                                    )}


                                </div>

                            </div>
                            {carlsloader && (
                                <div className='loader'>
                                    <Player
                                        autoplay
                                        loop
                                        src={loaderGif}
                                    // style={{ height: '50px', width: '50px' }}
                                    >
                                        <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                                    </Player>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='col3'>
                        {latest.length > 0 && (
                            <h2>New Uploads</h2>
                        )}

                        <div className='col-inner-cont'>
                            <div className='uploads_container' ref={carsl} >
                                <div className='uploads_row'>

                                    {latest.slice(0, displayedItemsCount).map((item, index) => (
                                        <div className={isShown === item.id && isCategory === 'latest' ? 'each_ups active' : 'each_ups'} key={index}
                                            onMouseEnter={() => handleMouseEnter(item.id, 'latest')}
                                            onMouseLeave={() => handleMouseLeave()}>



                                            {item.media?.media_url && isShown === item.id && isCategory === 'latest' && isHovered ? (
                                                <video autoPlay muted loop id="myVideo" ref={videoRef2} onClick={() => openPlayer(item.id)}>
                                                    <source src={item.media?.media_url} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            ) : (
                                                <img src={item.banner} alt='' onClick={() => openPlayer(item.id)} />
                                            )}
                                            <div className='dtl_flex'>
                                                <p onClick={() => openPlayer(item.id)}>{item.title}</p>
                                                <p className='duration'>{videoDuration}</p>
                                                {/* {likedVideos.includes(item.id) ?
                                                    (<FaHeart size={20} color="red" className='like' onClick={() => likeVid(item.id)} />) :
                                                    (<FaHeart size={20} color="white" className='like' onClick={() => likeVid(item.id)} />)
                                                } */}
                                            </div>
                                            <p className='title-mobile'>{item.title}</p>
                                        </div>
                                    )

                                    )}


                                </div>

                            </div>
                            {carlsloader && (
                                <div className='loader'>
                                    <Player
                                        autoplay
                                        loop
                                        src={loaderGif}
                                    // style={{ height: '50px', width: '50px' }}
                                    >
                                        <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                                    </Player>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='col3'>
                        {mostWatched.length > 0 && (
                            <h2>Most Watched</h2>
                        )}

                        <div className='col-inner-cont'>
                            <div className='uploads_container' ref={carsl2} >
                                <div className='uploads_row'>

                                    {mostWatched.slice(0, displayedItemsCount2).map((item, index) => (
                                        <div className={isShown === item.id && isCategory === 'mostwatched' ? 'each_ups active' : 'each_ups'} key={index}
                                            onMouseEnter={() => handleMouseEnter(item.id, 'mostwatched')}
                                            onMouseLeave={() => handleMouseLeave()}>

                                            {item.media?.media_url && isShown === item.id && isCategory === 'mostwatched' ? (
                                                <video autoPlay muted loop id="myVideo" ref={videoRef} onClick={() => openPlayer(item.id)}>
                                                    <source src={item.media?.media_url} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            ) : (
                                                <img src={item.banner} alt='' onClick={() => openPlayer(item.id)} />
                                            )}
                                            <div className='dtl_flex'>
                                                <p onClick={() => openPlayer(item.id)}>{item.title}</p>
                                                {/* {likedVideos.includes(item.id) ?
                                                (<FaHeart size={20} color="red" className='like' onClick={() => likeVid(item.id)} />) :
                                                (<FaHeart size={20} color="white" className='like' onClick={() => likeVid(item.id)} />)
                                            } */}
                                            </div>
                                            <p className='title-mobile'>{item.title}</p>
                                        </div>
                                    ))}


                                </div>

                            </div>
                            {carls2loader && (
                                <div className='loader'>
                                    <Player
                                        autoplay
                                        loop
                                        src={loaderGif}
                                    // style={{ height: '150px', width: '150px' }}
                                    >
                                        <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                                    </Player>
                                </div>)}
                        </div>

                    </div>

                    <div className='col3'>
                        {pickedforYou.length > 0 && (
                            <h2>Picked for You</h2>
                        )}

                        <div className='col-inner-cont'>
                            <div className='uploads_container' ref={carsl4} >
                                <div className='uploads_row'>

                                    {pickedforYou.slice(0, displayedItemsCount4).map((item, index) => (
                                        <div className={isShown === item.id && isCategory === 'pickedforYou' ? 'each_ups active' : 'each_ups'} key={index}
                                            onMouseEnter={() => handleMouseEnter(item.id, 'pickedforYou')}
                                            onMouseLeave={() => handleMouseLeave()}>

                                            {item.media?.media_url && isShown === item.id && isCategory === 'pickedforYou' ? (
                                                <video autoPlay muted loop id="myVideo" ref={videoRef} onClick={() => openPlayer(item.id)}>
                                                    <source src={item.media?.media_url} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            ) : (
                                                <img src={item.banner} alt='' onClick={() => openPlayer(item.id)} />
                                            )}
                                            <div className='dtl_flex'>
                                                <p onClick={() => openPlayer(item.id)}>{item.title}</p>
                                                {/* {likedVideos.includes(item.id) ?
                                                    (<FaHeart size={20} color="red" className='like' onClick={() => likeVid(item.id)} />) :
                                                    (<FaHeart size={20} color="white" className='like' onClick={() => likeVid(item.id)} />)
                                                } */}
                                            </div>
                                            <p className='title-mobile'>{item.title}</p>
                                        </div>
                                    ))}


                                </div>

                            </div>
                            {carls4loader && (
                                <div className='loader'>
                                    <Player
                                        autoplay
                                        loop
                                        src={loaderGif}
                                    // style={{ height: '150px', width: '150px' }}
                                    >
                                        <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                                    </Player>
                                </div>)}
                        </div>

                    </div>
                </div>
            </div>
        </>

    )
}

export default HomePage